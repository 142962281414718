@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

body {
  font-family: 'Open Sans', sans-serif;
  background: #FFE6E6;
  margin: 0;
  padding: 0;
  text-align: center;
}

.container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
}

.logo {
  width: 100px;
}

h1 {
  color: #E63946;
  font-size: 24px;
  font-weight: 700;
}

h2 {
  font-size: 20px;
  font-weight: 600;
}

p {
  color: #333;
  font-size: 16px;
  font-weight: 400;
}

/* button {
  background-color: #E63946;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background-color: #D62828;
}
 */
